/*============= developer_banner_area css ============*/
$title: #051441;
$pcolor: #545454;
$green: #64f4ac;

// font variable
$pop: "Poppins", sans-serif;

.developer_banner_area.frelencer_banner_area {
  height: auto;
  padding-top: 170px;
  position: relative;
  .d_shap {
    position: absolute;
    top: 0;
    left: 0;
  }
  .overlay_color {
    position: absolute;
    width: 570px;
    height: 100%;
    right: 0;
    top: 0;
    background-color: rgb(229, 239, 253);
    opacity: 0.53;
    z-index: -1;
    @media (max-width: 1199px) {
      width: 470px;
    }
  }
}

.developer_content.frelencer_content {
  position: relative;
  z-index: 1;
  padding-top: 70px;
  @media (max-width: 1199px) {
    margin-right: 0;
  }
  @media (max-width: 991px) {
    padding-top: 0;
  }
  .d_dot {
    position: absolute;
    left: -80px;
    top: 0px;
    z-index: -1;
  }
  h4 {
    span {
      background: $green;
      border-radius: 4px;
      color: $title;
      margin-bottom: 25px;
      font-family: $pop;
      font-weight: 500;
      @media (max-width: 576px) {
        padding: 7px 15px;
      }
    }
  }
  h2 {
    font-size: 58px;
    line-height: 1.3;
    font-family: $pop;
    font-weight: 600;
    color: $title;
    @media (max-width: 1199px) {
      font-size: 48px;
    }
    @media (max-width: 767px) {
      font-size: 38px;
    }
  }
  p {
    font-size: 16px;
    color: $pcolor;
    font-family: $pop;
    @media (max-width: 767px) {
      white-space: inherit;
    }
  }
  .chat_btn {
    font-size: 20px;
    font-weight: 600;
    color: $green;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    margin-top: 40px;
    &:before {
      content: "";
      width: 100%;
      height: 2px;
      background: $green;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

.developer_img {
  margin-right: -90px;
  text-align: right;
  margin-top: 60px;
  @media (max-width: 1440px) {
    margin-right: 0px;
  }
  @media (max-width: 991px) {
    text-align: center;
    margin-right: 0;
  }
  .f_img_one {
    bottom: 150px;
    top: auto;
    left: 160px;
    z-index: -1;
    @media (max-width: 1440px) {
      left: 67px;
    }
    @media (max-width: 600px) {
      left: 15px;
    }
  }
  .f_img_eight {
    top: 279px;
    left: -13px;
    @media (max-width: 1440px) {
      left: -23px;
    }
    @media (max-width: 576px) {
      left: -40px;
      top: 140px;
      display: block;
    }
  }
  .f_img_four {
    position: relative;
    right: 0;
    top: 0;
    @media (max-width: 576px) {
      max-width: 100%;
    }
  }
  .f_img_five {
    top: 70px;
    left: 99px;
    @media (max-width: 1440px) {
      left: 45px;
    }
    @media (max-width: 576px) {
      left: 0;
    }
  }
  .f_img_six {
    top: 0;
    left: auto;
    z-index: -1;
    right: -109px;
    @media (max-width: 1440px) {
      right: -25px;
    }
  }
  .f_img_seven {
    top: 180px;
    right: -200px;
    z-index: 0;
    @media (max-width: 1440px) {
      right: -73px;
    }
    @media (max-width: 767px) {
      right: 0px;
      display: block;
    }
  }
  .f_img_two {
    top: 50px;
    left: 80px;
    z-index: -1;
    @media (max-width: 576px) {
      display: none;
    }
  }
}
.developer_skill {
  padding: 20px 0px;
  left: -150px;
  position: relative;
  margin-top: 120px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 13px 237px 0px rgba(0, 0, 0, 0.04);
  @media (max-width: 1440px) {
    left: -30px;
  }
  @media (max-width: 1199px) {
    left: 0px;
    margin-top: 70px;
  }
  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
  .item {
    font-family: $pop;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.5;
    color: $pcolor;
    font-size: 15px;
    padding: 0px 38px;
    @media (max-width: 1199px) {
      width: 100%;
    }
    @media (max-width: 991px) {
      padding: 0px 24px;
      width: 100%;
    }
    @media (max-width: 767px) {
      width: 50%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
    p {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }
    span {
      font-size: 56px;
      color: $title;
      margin-right: 15px;
    }
  }
}

/*=========== dev_about_area css  ===========*/
.dev_about_area {
  padding: 120px 0px;
}
.dev_skill_item {
  .item {
    background: #fff;
    box-shadow: 0px 50px 150px 0px rgba(4, 73, 89, 0.05);
    padding: 30px 60px 30px;
    transition: all 0.2s;
    font-family: $pop;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    @media (max-width: 1199px) {
      padding: 30px 60px 30px 30px;
    }
    h3 {
      font-size: 28px;
      color: $title;
      font-weight: 600;
      font-family: $pop;
      transition: color 0.2s linear;
    }
    p {
      color: $pcolor;
      font-size: 18px;
    }
    .icon {
      position: absolute;
      right: 40px;
      top: 40px;
      font-size: 30px;
      color: $green;
    }
    a {
      color: $title;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 500;
      display: inline-block;
      margin-top: 15px;
      line-height: 1.2;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 0;
        bottom: 0;
        left: 0;
        height: 1px;
        background: $title;
        transition: all 0.2s linear;
      }
      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
    & + .item {
      margin-top: 10px;
    }
    &:hover {
      box-shadow: 0 4px 60px 0 rgba(4, 73, 89, 0.05),
        0 12px 100px 0 rgba(4, 73, 89, 0.06);
      h3 {
        color: $green;
      }
    }
  }
}
.dev_about_content {
  padding-left: 100px;
  @media (max-width: 1199px) {
    padding-left: 30px;
  }
  @media (max-width: 991px) {
    padding-left: 0;
    margin-bottom: 30px;
  }
  h6,
  h2,
  h5,
  p {
    font-family: $pop;
  }
  h6 {
    font-size: 18px;
    color: $pcolor;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 42px;
    white-space: pre-line;
    font-weight: 600;
    color: $title;
    margin-bottom: 30px;
  }
  h5 {
    font-size: 22px;
    line-height: 1.6;
    color: $title;
    font-style: italic;
    margin-bottom: 20px;
  }
  p {
    color: $pcolor;
    line-height: 32px;
  }
}
/*=========== dev_about_area css  ===========*/

/*=========== dev_clients_logo_area css  ===========*/
.dev_clients_logo_area {
  padding-top: 50px;
  @media (max-width: 991px) {
    padding-top: 0;
  }
  .clients_intrigration {
    flex-wrap: wrap;
    @media (max-width: 991px) {
      width: 100%;
      height: auto;
    }
    .c_items {
      position: relative;
      width: calc(95% / 5);
      height: 85px;
      left: 0 !important;
      right: 0 !important;
      top: auto !important;
      bottom: auto !important;
      margin: 5px 5px 20px !important;
      box-shadow: none;
      @media (max-width: 576px) {
        width: 29%;
        margin-bottom: 10px;
      }
    }
  }
}

/*=========== dev_clients_logo_area css  ===========*/

.dev_tittle {
  h2,
  p {
    font-family: $pop;
  }
  h2 {
    font-size: 42px;
    font-weight: 600;
    color: $title;
    margin-bottom: 0px;
  }
  p {
    color: $pcolor;
    font-size: 16px;
  }
}
.dev_btn {
  font-size: 20px;
  font-weight: 600;
  color: $green;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  &:before {
    content: "";
    width: 100%;
    height: 2px;
    background: $green;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: width 0.4s linear;
  }
  &:hover {
    color: $green;
    &::before {
      width: 0;
      right: 0;
      left: auto;
    }
  }
}
.dev_work_area {
  padding: 140px 0px 120px;
  .custome_container {
    @media (min-width: 1440px) {
      max-width: 1440px;
    }
  }
  .dev_tittle {
    margin-bottom: 80px;
    padding-left: 150px;
    @media (max-width: 1199px) {
      padding-left: 0;
    }
  }
  .row {
    margin-left: -40px;
    margin-right: -40px;
    margin-bottom: -80px;
    @media (max-width: 1199px) {
      margin-left: -20px;
      margin-right: -20px;
    }
    @media (max-width: 991px) {
      margin-left: -15px;
      margin-right: -15px;
    }
    .grid-item {
      padding-left: 40px;
      padding-right: 40px;
      margin-bottom: 80px;
      @media (max-width: 1199px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media (max-width: 991px) {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 30px;
      }
    }
  }
  .work_item {
    padding: 0px;
    border: 0px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-bottom: 0;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        -10deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
      );
      position: absolute;
    }
    img {
      max-width: 100%;
      position: relative;
      z-index: 0;
      transition: all 0.3s linear;
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 40px;
      h3 {
        margin-bottom: 0;
        font-size: 32px;
        color: #fff;
        font-family: $pop;
      }
      .category {
        a {
          padding: 5px 8px;
          border-radius: 3px;
          background: #fff;
          color: $pcolor;
          font-size: 14px;
          font-weight: 600;
          margin: 0px 3px;
        }
      }
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
  .dev_btn {
    margin-top: 120px;
    float: right;
    margin-right: 150px;
    @media (max-width: 767px) {
      float: left;
      margin-top: 50px;
      margin-right: 0;
    }
  }
}

// testimonial_area_three css
.mb_70 {
  margin-bottom: 70px;
}
.testimonial_area_three {
  background: #f8f8f8 !important;
  position: relative;
  .shape {
    position: absolute;
    z-index: -1;
    &.one {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &.two {
      top: -20px;
    }
  }
  &::before {
    display: none;
  }
  .testimonial_slider {
    padding-left: 0 !important;
    @media (max-width: 767px) {
      padding-right: 0 !important;
    }
    .slick-list {
      padding-top: 50px;
    }
    .item {
      border-radius: 10px;
      background: rgba(229, 239, 253, 0.5);
      color: $pcolor !important;
      text-align: left;
      padding: 20px 120px 50px 50px;
      font-family: $pop;
      position: relative;
      @media (max-width: 576px) {
        padding: 20px 10px 50px 20px;
      }
      &:before {
        content: "\7b";
        position: absolute;
        right: 0;
        top: 40px;
        font-family: eleganticons;
        color: #fff;
        opacity: 0.5;
        font-size: 150px;
      }
      .author_img {
        border: 0px;
        margin-top: -80px;
      }
      h6 {
        color: $title !important;
        font-family: $pop !important;
        text-transform: capitalize;
        margin-bottom: 0px !important;
        line-height: 1;
      }
    }
  }
  .slick-dots {
    bottom: 0;
    margin-top: 20px;
    li {
      button {
        background: rgb(229, 239, 253) !important;
      }
      &.slick-active {
        button {
          background: $green !important;
        }
      }
    }
  }
}

// exprience area
.sec_pad {
  padding: 120px 0px;
  position: relative;
  z-index: 1;
}
.exprience_area {
  .shape {
    position: absolute;
    z-index: -1;
    &.one {
      left: 50%;
      transform: translateX(-50%);
      top: 130px;
    }
    &.two {
      right: -5px;
      top: 0px;
    }
    &.three {
      left: 40px;
      top: 50px;
    }
    &.four {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.exprience_inner {
  margin-bottom: -30px;
  .exprience_item {
    border-radius: 10px;
    padding: 20px;
    background: #fff;
    box-shadow: 0px 50px 150px 0px rgba(4, 73, 89, 0.05);
    align-items: center;
    font-family: $pop;
    margin-bottom: 30px;
    transition: all 0.3s linear;
    cursor: pointer;
    @media (max-width: 576px) {
      display: block;
    }
    .media-left {
      margin-right: 20px;
      img {
        transition: all 0.3s linear;
      }
    }
    h4 {
      color: $title;
      font-weight: 600;
      margin-bottom: 0;
      font-family: $pop;
      transition: color 0.2s linear;
    }
    span {
      color: $pcolor;
      opacity: 0.6;
      font-size: 14px;
      line-height: 1;
    }
    p {
      margin-bottom: 0;
      color: $pcolor;
      margin-top: 8px;
    }
    &:hover {
      box-shadow: 0 40px 60px 0 rgba(4, 73, 89, 0.03),
        0 12px 100px 0 rgba(4, 73, 89, 0.05);
      h4 {
        color: $green;
      }
      .media-left {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

// awards_area css
.bg_color_two {
  background: #f8f8f8;
}
.awards_area {
  .shape {
    position: absolute;
    top: 0;
    z-index: -1;
  }
}

/*=========== awards_area css =========*/
.awards_area {
  overflow: hidden;
  [class*=" col-"] {
    &:nth-child(1) {
      .icon {
        &::before {
          background: #e0f7ef;
          border-radius: 62% 38% 61% 39%/46% 57% 43% 54%;
        }
      }
    }
    &:nth-child(2) {
      .icon {
        &::before {
          background: rgba(6, 36, 249, 0.6%);
          border-radius: 39% 81% 50% 59%/46% 57% 43% 54%;
        }
      }
    }
    &:nth-child(3) {
      .icon {
        &::before {
          background: #dde9fa;
          border-radius: 19% 99% 32% 88%/52% 65% 100% 81%;
        }
      }
    }
  }
}
.awards_item {
  padding: 40px;
  display: block;
  font-family: $pop;
  background: #fff;
  border-radius: 5px;
  transition: all 0.3s linear;
  cursor: pointer;
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
  .icon {
    margin-bottom: 30px;
    position: relative;
    height: 125px;
    width: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      left: 0;
      height: 100%;
      z-index: -1;
      transition: all 200ms ease;
      background: #e0f7ef;
      border-radius: 62% 38% 61% 39%/46% 57% 43% 54%;
    }
    img {
      transition: all 200ms ease;
    }
  }
  h4 {
    font-family: $pop;
    color: $title;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.01em;
    margin-bottom: 15px;
  }
  p {
    color: $pcolor;
  }
  .dev_btn {
    font-size: 14px;
    color: $green;
    line-height: 1.3;
    &::before {
      background: $green;
    }
  }
  &:hover {
    border-radius: 0px 100px 0px 0px;
    .icon {
      &::before {
        border-radius: 30% 50% 50% 50%/60% 50% 20% 54% !important;
      }
      img {
        transform: scale(1.1);
      }
    }
  }
}

// d_blog_area_two css
.d_blog_area_two {
  .blog_post {
    .blog_img .post_date {
      font-family: $pop;
    }
    .post_content {
      font-family: $pop;
      color: $pcolor;
      h2 {
        font-family: $pop;
        color: $title;
        &:hover {
          color: $green;
        }
      }
      .blog-meta span a {
        color: $pcolor;
      }
      .read_btn {
        color: #64f4ac;
        font-family: $pop;
        &:before {
          background: $green;
        }
        &:hover {
          color: $green;
        }
      }
    }
  }
}

.dContact {
  .section_title_two {
    h6,
    h2 {
      font-weight: 600;
    }
    h6,
    h2,
    p {
      font-family: $pop;
    }
    h6 {
      color: $green;
    }
    h2 {
      color: $title;
    }
    p {
      color: $pcolor;
    }
  }
  .get_item .media-body h6,
  .get_item .media-body p,
  .input_form form .theme_btn.send_btn {
    font-family: $pop;
  }
  .get_item .media-body h6 {
    font-weight: 600;
    color: $pcolor;
  }
  .get_item i:before {
    color: $green;
  }
  .input_form form .theme_btn.send_btn {
    background: $green;
    border-color: $green;
    &:before {
      background: $green;
    }
  }
}

.d_footer_area.footer-area-two {
  background: $green;
  .footer-content {
    h6,
    p {
      font-family: $pop;
    }
  }
}
